/* ============
 * Getters for the exercise module
 * ============
 *
 * The getters that are available on the
 * exercise module.
 */

import {
  G,
  W,
  has,
  eql,
  all,
  pick,
  when,
  extend,
  values,
  filter,
  compose,
  shuffle,
  firstOf,
  identity, reduce
} from '@2l/utils'

const isWordBuilding = eql('type', 'composeWord')
const isPhraseBuilding = when({
  type: W('composePhraseByVideo composePhraseByText composePhraseByImage')
})
const isPhraseBuildingByVideo = when({
  type: W('composePhraseByVideo')
})
const isChooseByImage = when({
  type: W('chooseByImage')
})
const isExplanation = eql('type', 'explain')
const isExplainWord = eql('type', 'explainWord')

const isValidExerciseType = when({
  type: W('chooseByImage explain phraseBuilding composePhraseByText')
})
const isValidExerciseClass = when({
  type: W('composePhraseByText chooseByImage')
})
const isCenteredClass = when({
  type: W('composePhraseByVideo composePhraseByText composePhraseByImage speechExercise')
})

const isExerciseForWords = G('exerciseForWords')

export default {
  isWordBuilding,
  isPhraseBuilding,
  isPhraseBuildingByVideo,
  isChooseByImage,
  isExplanation,
  isExplainWord,
  isValidExerciseType,
  isValidExerciseClass,
  isCenteredClass,
  isExerciseForWords,

  isSelected: firstOf(all(G('_id'), identity(true)), identity(false)),

  isDialog: eql('type', 'dialog'),

  isSpeechRecognition: eql('type', 'speechExercise'),

  isChooseAnswerByText: eql('type', 'chooseAnswerByText'),

  hasImage: G('image._id'),
  hasVideo: G('video._id'),

  getVideoBlock: pick({
    url: 'video.medium',
    thumbnails: 'video.thumbnail',
    autoPlay: identity(true)
  }),

  isBuilding: firstOf(isWordBuilding, isPhraseBuilding),
  isExplain: firstOf(isExplanation, isExplainWord),

  getText: G('text'),
  getHint: firstOf(G('hint'), G('currentReplica.hint')),
  getDescription: G('description'),

  getImage: G('image.xl'),
  getImagePlaceholder: G('image.s'),

  isVerifiedResult: G('verifiedResult'),

  getCorrectVariant: G('correctWord'),
  getCurrentReplica: G('currentReplica'),
  getCorrectWordOriginal: G('correctWordOriginal'),

  hasReplica: G('currentReplica.answers'),
  hasAnswers: G('answers'),
  hasReplicas: G('replicas'),

  getAvatars: G('avatars'),
  getAnswers: all(G('answers'), compose(G('answers'), Object.keys, shuffle)),
  getCurrentReplicaAnswers: compose(
    firstOf(G('currentReplica.answers'), () => []),
    shuffle
  ),
  getTranslatedMeanings: G('translatedMeanings'),
  getFirstTranslatedMeanings: G('translatedMeanings.0'),
  getOrigin: G('origin'),
  getExampleOrigin: G('exampleOrigin'),
  getExampleTranslation: G('exampleTranslation'),

  getSymbols: G('symbols'),
  getPlayableKeys: G('playableKeys'),
  getSelectedSymbols: G('selectedSymbols'),
  getReadyToCheck: G('readyToCheck'),
  getIntermediateResult: G('intermediateResult'),
  getMistakes: G('mistakes'),
  getSelectedRightAnswer: G('selectedRightAnswer'),
  getSelectedAnswer: G('selectedAnswer'),

  getParts: (state) => {
    const {
      correctPhraseLength,
      selectableParts,
      selectedParts = [],
      parts
    } = state

    if (!correctPhraseLength) { return null }

    const list = new Array(correctPhraseLength)

    for (const part in parts) {
      const indexes = parts[part]

      for (const index of indexes) {
        if (typeof index !== 'number') { continue }

        const selectable = has(selectableParts, index.toString())
        const selected = selectedParts[selectableParts[index]]

        list[index] = { value: part, selectable, selected }
      }
    }

    return list
  },
  getReplicas: (state) => {
    const { questions = [], selectedReplicas } = state

    if (!questions.length) { return questions }

    const avatars = values(state.avatars)
    const nextIndex = selectedReplicas.length
    const entries = []

    for (let i = 0; i < nextIndex; i++) {
      entries.push(
        {
          index: i,
          text: questions[i],
          avatar: avatars[0],
          selectable: false,
          bordered: true
        },
        {
          index: i + 1,
          text: selectedReplicas[i],
          avatar: avatars[1],
          selected: true,
          bordered: true,
          selectable: true
        }
      )
    }

    if (questions[nextIndex]) {
      entries.push({
        index: entries.length,
        text: questions[nextIndex],
        avatar: avatars[entries.length % 2],
        selectable: false,
        bordered: false
      })

      entries.push({
        index: entries.length,
        text: '...',
        avatar: avatars[entries.length % 2],
        selected: false,
        selectable: true,
        bordered: false
      })
    }

    return entries
  },
  getSelectableParts: (state) => {
    const {
      correctPhraseLength,
      selectableParts,
      selectedParts,
      partsByIndex
    } = state

    if (!correctPhraseLength) { return null }

    const selectableIndexes = filter(
      i => selectableParts[i] >= selectedParts.length,
      Object.keys(selectableParts)
    )
    const partsWithKeys = reduce(selectableIndexes, (result, item, index) => {
      const part = partsByIndex[Number(item)]

      result[index] = {
        text: part,
        id: item
      }
      return result
    }, [])

    return shuffle(partsWithKeys)
  },
  getKeyboardInfo: (state, getters) => {
    const {
      symbols,
      correctWord,
      readyToCheck,
      selectedSymbols,
      intermediateResult,
      _id
    } = state
    const { isExerciseForWords } = getters

    const availableSymbols = extend(symbols)

    for (const symbol in selectedSymbols) { availableSymbols[symbol] -= selectedSymbols[symbol] }

    return {
      readyToCheck,
      selectedSymbols,
      availableSymbols,
      correctWord,
      isExerciseForWords,
      intermediateResult,
      _id
    }
  },
  getExerciseComponents: (state, getters) => {
    const { isExerciseForWords } = getters
    if (!isWordBuilding(state) && !isExerciseForWords) { return null }

    const {
      text,
      correctWordOriginal,
      countSelectedSymbols,
      selectedWordComponents
    } = state

    const [left, right] = text.split('{word}')

    const expectedLength = correctWordOriginal.length - countSelectedSymbols
    const symbols = selectedWordComponents
      .concat(expectedLength >= 0 ? Array(expectedLength) : [])

    return { left, symbols, right }
  },
  checkFailureWordComponents: (state) => {
    const { selectedResult, verifiedResult } = state

    return !selectedResult && verifiedResult
  },
  checkSuccessWordComponents: (state) => {
    const { selectedResult, verifiedResult } = state

    return selectedResult && verifiedResult
  }
}
