/* eslint-disable no-bitwise */
export function uuidV4 () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0
    const v = c === 'x'
      ? r
      : ((r & 0x3) | 0x8)

    return v.toString(16)
  })
}

export const isRestrictedDomain = document.location.host.split('.').pop() === 'ru' ||
  new URLSearchParams(document.location.search).has('dev_ru')

export function getParameterByName (name) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')

  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`)
  // eslint-disable-next-line no-restricted-globals
  const results = regex.exec(location.search)

  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export function readCookie (name) {
  const components = document.cookie.split('; ') || ''

  for (const component of components) {
    const [key, value] = component.split('=')

    if (key === name) { return value }
  }

  return null
}

export function PromiseTimeout (timeout = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, timeout)
  })
}

export function isEmailValid (email) {
  if (typeof email !== 'string') { return }

  return email.match(
    // https://stackoverflow.com/questions/46155/whats-the-best-way-to-validate-an-email-address-in-javascript
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

class TimeoutExceededError extends Error {
  constructor (message = '') {
    super(message)
    this.name = 'TIMEOUT_EXCEEDED'
    this.message = message
  }
}

export function throwOnTimeoutFunction (timeout = 300) {
  return (_, reject) => setTimeout(() => reject(new TimeoutExceededError('go async')), timeout)
}

export function isTimeoutExceededError (err) {
  return err && err.name === 'TIMEOUT_EXCEEDED'
}

export function getSupportedCodec () {
  // are widely supported by all modern browsers (Chrome, Firefox, Safari, Edge, etc.)
  // https://caniuse.com/?search=vp9
  return ['vp9', 'webm']
}

export default {
  uuidV4,
  readCookie,
  isEmailValid,
  PromiseTimeout,
  getSupportedCodec,
  isRestrictedDomain,
  getParameterByName,
  throwOnTimeoutFunction
}
